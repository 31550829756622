@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS */
* {
  @apply transition-colors duration-200;
}

/* .dialog-container {
  height: 100%; 
  display: flex;
  flex-direction: column;
} */

body {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

nav.scrolled {
  @apply shadow-2xl;
  border-bottom: 0px;
}
#btn-more {
  display: none;
}

@media screen and (max-width: 640px) {
  #about-more {
    display: none;
  }
  #btn-more {
    display: block;
  }
}

.icons {
  font-size: 32px;
}

@media screen and (max-width: 640px) {
  .icons {
    font-size: 20px;
  }
}

/* Phone screens */
@media screen and (max-width: 640px) {
  .nav-div {
    padding-left: 0;
    padding-right: 0;
  }
  .middle-nav {
    display: none;
  }
  .right-nav {
    display: flex;
  }
}

h2 {
  @apply text-xl;
}

.hero {
  grid-row-gap: 20px;
}

@media screen and (max-width: 1024px) {
  #imageText {
    font-size: 12px;
  }
}

@media screen and (max-width: 640px) {
  #imageText {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
  .hero-left {
    display: flex;
  }
}

.section {
  @apply lg:px-36;
}

.section-desc {
  @apply text-xl;
}

#small-text {
  font-size: 1rem;
}

.blackjack-pic {
  height: 23rem;
}

@media screen and (max-width: 640px) {
  .blackjack-pic {
    height: 14rem;
  }
}

.mobile-view {
  display: none;
}

@media screen and (max-width: 640px) {
  .normal-view {
    display: none;
  }
  .mobile-view {
    display: inline-flex;
  }
  .more-desc {
    display: none;
  }
}

#msg {
  display: none;
  transition: all 1s ease;
  opacity: 1;
}

.yarl__slide_title {
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%)
}

@media screen and (max-width: 768px) {
  .yarl__counter {
    bottom: 0 !important;
    left: 0 !important;
    right: auto !important;
    top: auto !important;
  }
  .yarl__slide_title {
    text-align: center;
    position: relative;
    left: auto;
    right: auto;
    transform: none;
  }
  .yarl__slide_description {
    text-align: end !important;
  }
}

@media screen and (max-width: 1024px){
  [aria-label="Zoom in"], [aria-label="Zoom out"] {
    display: none !important;
  }
}